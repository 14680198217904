<nav class="sidebar-content-layout">
  <div class="d-flex flex-column justify-content-between align-items-start h-100 overflow-hidden">
    <div class="w-100 pt-20">
      <div class="row mb-30">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <a routerLinkActive="active" routerLink="/dashboard" data-toggle="layout">
            @if (innerWidth >= 1400) {
              <img src="https://static-assets.vinlivt.de/logo/b2b/light%401x.png" width="115" alt="logo" />
            } @else {
              <img src="https://static-assets.vinlivt.de/logo/icon/b2b-icon.svg" width="30" alt="logo" />
            }
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ul class="nav-main px-3">
            <li>
              <a routerLinkActive="active" routerLink="/dashboard" data-toggle="layout">
                <img src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Basic/Home.svg" alt="" />

                @if (innerWidth >= 1400) {
                  <span class="font-w400">Dashboard</span>
                }
              </a>
            </li>

            <li>
              <a routerLinkActive="active" routerLink="/activity" data-toggle="layout">
                <img src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Basic/Alarm.svg" alt="activity" />

                @if (innerWidth >= 1400) {
                  <span class="font-w400">Aktivitäten</span>
                }
              </a>
            </li>

            <li>
              <a routerLinkActive="active" routerLink="/clients" data-toggle="layout">
                <img src="https://static-assets.vinlivt.de/icons/line_icons_2.0/User/Users-1.svg" alt="" />

                @if (innerWidth >= 1400) {
                  <span class="font-w400">Kundenliste</span>
                }
              </a>
            </li>

            <li>
              <a routerLinkActive="active" [routerLink]="['/contracts-overview']" data-toggle="layout">
                <img
                  src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Warning/Shield_Check.svg"
                  alt="contracts"
                />

                @if (innerWidth >= 1400) {
                  <span class="font-w400">Versicherungen</span>
                }
              </a>
            </li>

            <li *appHasRole="[Roles.CHAT]">
              <a class="position-relative" routerLinkActive="active" routerLink="/news" data-toggle="layout">
                <img
                  src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Communication/Chat_Circle_Chats.svg"
                  alt=""
                />
                @if (innerWidth >= 1400) {
                  <span class="font-w400">Nachrichten</span>

                  @if (totalUnreadMessages > 0) {
                    <span class="ml-auto read-circle text-10">{{
                      totalUnreadMessages > 10 ? '10+' : totalUnreadMessages
                    }}</span>
                  }
                } @else {
                  @if (totalUnreadMessages > 0) {
                    <span class="read-circle"></span>
                  }
                }
              </a>
            </li>

            <li>
              <a routerLinkActive="active" [routerLink]="['/analyse']" data-toggle="layout">
                <img src="https://static-assets.vinlivt.de/icons/line-icons/svg/chart.svg" alt="analyse" />

                @if (innerWidth >= 1400) {
                  <span class="font-w400">Analysen</span>
                }
              </a>
            </li>

            <!--            @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {-->
            <!--              <li>-->
            <!--                <a routerLinkActive="active" routerLink="/calendar" data-toggle="layout">-->
            <!--                  <img-->
            <!--                    src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Basic/Calendar_Days.svg"-->
            <!--                    alt="calendar"-->
            <!--                  />-->
            <!--                  <span class="sidebar-mini-hide">Kalender</span>-->
            <!--                </a>-->
            <!--              </li>-->

            <!--              <li *appHasRole="[Roles.CAMPAIGN]">-->
            <!--                <a-->
            <!--                  routerLinkActive="active"-->
            <!--                  [routerLink]="['/campaign/overview']"-->
            <!--                  [queryParams]="{ draft: false }"-->
            <!--                  data-toggle="layout"-->
            <!--                >-->
            <!--                  <img src="https://static-assets.vinlivt.de/icons/line_icons_2.0/System/Radio.svg" alt="campaign" />-->
            <!--                  <span class="sidebar-mini-hide">Kampagnen</span>-->
            <!--                </a>-->
            <!--              </li>-->
            <!--            }-->

            @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {
              <li>
                <a
                  class="cursor-pointer"
                  routerLinkActive="active"
                  (click)="navigateToMediaStudio()"
                  data-toggle="layout"
                >
                  <img src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Social/Youtube.svg" alt="media" />

                  @if (innerWidth >= 1400) {
                    <span class="font-w400">Mediastudio</span>

                    @if (!authService.hasRole(Roles.MEDIA_STUDIO)) {
                      <span class="ml-auto">
                        <i class="bi bi-award"></i>
                      </span>
                    }
                  }
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>

    <div class="bottom-content w-100">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center mb-2">
          @if (innerWidth >= 1400) {
            <div
              class="br-16 advertise-consultant-block d-flex align-items-center justify-content-center flex-column p-15 mx-15"
            >
              @if (innerHeight > 790) {
                <div class="text-secondary text-center">Berater werben und 50€ Gutschrift sichern.</div>
                <img
                  class="mt-10"
                  ngSrc="https://static-assets.vinlivt.de/icons/premium/clay/320/PIg%202.png"
                  width="90"
                  height="90"
                  priority
                  alt="ticket"
                />
              }

              <button class="btn btn-block btn-noborder btn-outline" (click)="goToOtherExternalUrl()">
                Jetzt sichern
              </button>
            </div>
          } @else {
            <div
              class="advertise-consultant-block d-flex align-items-center justify-content-center cursor-pointer br-16 p-2"
              (click)="goToOtherExternalUrl()"
            >
              <img
                ngSrc="https://static-assets.vinlivt.de/icons/premium/clay/320/PIg%202.png"
                width="30"
                height="30"
                priority
                alt="ticket"
              />
            </div>
          }
        </div>

        <!--        <div class="col-12 d-flex align-items-center justify-content-center">-->
        <!--          @if (innerWidth >= 1400 && company?.logoUrl) {-->
        <!--            <div class="text-center">-->
        <!--              <img [src]="company?.logoUrl" width="100" alt="logo" />-->
        <!--            </div>-->
        <!--          }-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</nav>
