<div class="broker-mandate-modal-container">
  <!--  @if (totalPending.count > 0) {-->
  <div class="button-wrapper" style="height: 42px">
    <button
      type="button"
      id="page-header-new-mandates"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="true"
      class="btn btn-lg btn-circle btn-light-yellow btn-noborder mr-10"
      style="height: 42px; width: 42px"
      (click)="loadPendingMandates()"
    >
      <i class="bi bi-pen text-18"></i>
    </button>

    @if (totalPending.count > 0) {
      <div class="red-point"></div>
    }

    <div
      class="dropdown-menu dropdown-menu-right new-borker-mandate-wrapper shadow-lg pop-up-border"
      aria-labelledby="page-header-new-mandates"
      x-placement="bottom-end"
      style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(-231px, 36px, 0px)"
    >
      <h5 class="h6 py-10 mb-10 border-b">Neue Mandate</h5>

      @if (brokerMandateApprovalList?.length) {
        <table class="table table-vcenter table-borderless full-width-table mt-0">
          <tbody>
            @for (mandateApproval of brokerMandateApprovalList; track mandateApproval) {
              <tr>
                <td class="client-pic cursor-pointer" [routerLink]="['/clients/detail', mandateApproval?.person?.id]">
                  <img
                    style="border-radius: 50%"
                    ngSrc="{{
                      mandateApproval?.person?.profilePictureUrl ?? 'https://static-assets.vinlivt.de/img/misc/bird.png'
                    }}"
                    alt="advisor avatar"
                    width="35"
                    height="35"
                  />
                </td>

                <td class="name-cell cursor-pointer" [routerLink]="['/clients/detail', mandateApproval?.person?.id]">
                  {{ mandateApproval?.person?.firstname }} {{ mandateApproval?.person?.lastname }}
                </td>

                <td class="d-none d-sm-table-cell" style="width: 100px">
                  {{ mandateApproval.created | date: 'dd.MM.yyyy' }}
                </td>

                <td class="text-center" style="width: 100px">{{ mandateApproval.created | date: 'HH:MM' }} Uhr</td>

                <td class="text-center">
                  <app-verify-mandate-modal
                    [user]="mandateApproval?.person"
                    [brokerMandateApprovalModel]="mandateApproval"
                    (reloadTrigger)="countPendingMandates()"
                  ></app-verify-mandate-modal>
                </td>
              </tr>
            }
          </tbody>
        </table>
      } @else {
        <div class="row py-10">
          <div class="col-12 text-center mb-30">
            <img
              src="https://static-assets.vinlivt.de/icons/premium/clay/320/Pencil%201.png"
              alt="pencil"
              width="100"
              height="100"
            />
          </div>

          <div class="col-12 text-center">
            <div class="text-secondary">Keine neuen Mandate</div>
          </div>
        </div>
      }

      <div class="dropdown-divider"></div>

      <div class="row my-10">
        <div class="col-md-12 text-center">
          <a class="text-center mb-0" href="javascript:void(0)" [routerLink]="['/broker-mandate-approvals']">
            Alle ansehen
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--  } @else {-->
  <!--    <button-->
  <!--      *appHasRole="[Roles.MANDATE]"-->
  <!--      [routerLink]="['/broker-mandate-approvals']"-->
  <!--      type="button"-->
  <!--      class="btn btn-light-yellow btn-rounded btn-noborder float-right d-sm-inline-block"-->
  <!--      style="height: 42px"-->
  <!--    >-->
  <!--      <div class="d-flex d-md-none">-->
  <!--        <i class="bi bi-file-earmark-ruled text-16"></i>-->
  <!--      </div>-->

  <!--      <div class="d-none d-md-flex">Alle Mandate</div>-->
  <!--    </button>-->
  <!--  }-->
</div>
